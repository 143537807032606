import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { BehaviorSubject } from "rxjs";

import { DataService } from "./data.service";
import { LocalStorageService } from "./localstorage.service";
import { WindowService } from "./window.service";

@Injectable()
export class LanguageService {
  // localstorage string and it's observable variable
  browserLanguage: string;
  lang = "sl";
  languageSource = new BehaviorSubject<string>("sl");
  language$ = this.languageSource.asObservable();

  API = "";
  

  en: ITranslations = {
    login: "Login",
    loginToContact: "Login to contact this user.",
    noUserFound: "User not found.",
    email: "Email",
    website: "Website",
    sendEmail: "Send email",
    send: "Send",
    call: "Call",
    companyName: "Company name",
    companyId: "Company Id",
    taxNumber: "Tax Id",
    registryCity: "Register City",
    bankName: "Bank Name",
    bank: "Bank",
    basicInformation: "Basic information",
    iban: "Iban",
    swift: "Swift",
    getPhone: "Get phone",
    darkModeToggle: "Dark mode",
    lightModeToggle: "Light mode",
    phoneNumber: "Phone number",
    submitNumber: "Submit number",
    providePhoneNumber: "Provide a phone number",
    noPhoneProvided: "No phone provided.",
    phoneRequirement: "Before you can create an ad you should provide a phone number.",
    personalInfo: "Bio",
    emailNotValid: "Not a valid email",
    userNameNotValid: "Name must contain only letters or numbers, with no excess spaces.",
    userNameTaken: "Username already taken.",
    userNameTooShort: "Username too short.",
    userNameTooLong: "Username too long.",
    userNameRequired: "Username is required.",
    forSupport: "For support, bug reports and suggestions",
    sendUsMessage: "send us an Email",
    yourMessage: "Your message",
    messageTitle: "Subject",
    messages: "Messages",
    noMessagesYet: "No messages yet.",
    myConversations: "My conversations",
    myConversationWith: "My conversation with",
    password: "Password",
    confirmPassword: "Confirm password",
    passwordTooShort: "Password must contain at least 6 characters.",
    passwordsNoMatch: "Passwords do not match.",
    messageTooLong: "Your message is longer than 500 characters.",
    messageToOwner: "You can not send the message to yourself.",
    search: "Search",
    search2: "search",
    mapSearch: "Map search",
    searching: "Searching",
    locationCityZip: "Location, city, zip",
    location: "Location",
    zip: "zip",
    city: "city",
    county: "county",
    country: "country",
    noResults: "No results",
    noResultsFound: "No results found. Let's try a new",
    showResults: "Show results",
    rememberMe: "Remember me",
    signIn: "sign in",
    signInGoogle: "Sign in with Google",
    signInFacebook: "Sign in with Facebook",
    forgotPassword: "Forgot password?",
    resetPassword: "Reset password",
    firstName: "First name",
    lastName: "Last name",
    lastViewedAds: "Last viewed",
    adExpired: "ad expired",
    expiresIn: "Expires in",
    expiredSince: "Expired since",
    expiresToday: "Expires today",
    noExpirationData: "No expiration data",
    findHomeIn: "Find a home in",
    nickName: "nickname",
    byRegistering: "By registering you agree to our",
    iAgreeTo: "I agree to the",
    notifyMe: "Notify me",
    subscribeToSearchMessage: "Or subscribe to this search query and be the first to get notified!",
    subscriptionActive: "Email notification active for the next 30 days.",
    subscriptionDeactivated: "Email notification deactivated.",
    termsAndConditions: "Terms and conditions",
    termsAndConditions2: "Terms and conditions",
    cookiePolicy: "Cookie policy",
    privacyPolicy: "Privacy policy",
    register: "register",
    registration: "Registration",
    userVerificationError: "There was an error. Please, try again.",
    userTaken: "User with this email already exists.",
    wrongPasswordMatch: "The provided passwords do not match.",
    noPasswordSet: "Password not set for this email. Try Oauth.",
    options: "Options",
    delete: "Delete",
    activate: "Activate",
    deactivate: "Deactivate",
    edit: "Edit",
    remove: "Remove",
    removePost: "Remove post",
    removePostConfirm: "Do you want to remove this post?",
    whatsOnYourMind: "What's on your mind",
    more: "More",
    id: "ID",
    flag: "Flag",
    describeTheIssue: "Describe the issue.",
    descriptionAd: "Description",
    deleteConfirm: "Do you want to delete the selected elements?",
    restore: "Restore",
    share: "Share",
    move: "Move",
    star: "Star",
    color: "Color",
    cancel: "Cancel",
    back: "Back",
    close: "Close",
    hide: "Hide",
    home: "Home",
    recent: "Recent",
    favorites: "Favorites",
    noFavoritesYet: "No favorites yet.",
    bin: "Bin",
    profile: "Profile",
    myDashboard: "My dashboard",
    profanity: "Profanity",
    offensive: "Offensive",
    spam: "Spam",
    other: "Other",
    logout: "Logout",
    help: "Help",
    or: "or",
    at: "at",
    dropFileHere: "Drop file here",
    uploadLimit: "Limit of uploaded images is 12.",
    uploadMorePhotos: "Upload more photos",
    uploadConfirm: "Photos uploaded.",
    uploadFile: "Upload file",
    upload: "Upload",
    loadNewPosts: "Get new posts",
    posts: "Posts feed",
    menu: "menu",
    loading: "Loading...",
    fileDeleted: "deleted.",
    folderName: "Folder name",
    size: "Size",
    ok: "ok",
    sortBy: "Sort by",
    setAddressOnMap: "Set location on the map. (Accurate if possible)",
    address: "Address",
    addressNotFound: "Address not found. Please set the marker closer to a street.",
    registrationWarning: "WARNING: Be careful when receiving a message, call or text message. Never reveal your bank data.",
    name: "Name",
    days: "days",
    day: "day",
    dateCreated: "Date created",
    date: "date",
    adType: "Ad type",
    adPropType: "Property type",
    flatType: "Flat type",
    flatTypes: {
      garconiere: "Garconiere",
      flat1: "1-room",
      flat15: "1.5-rooms",
      flat2: "2-rooms",
      flat25: "2.5-rooms",
      flat3: "3-rooms",
      flat35: "3.5-rooms",
      flat4: "4-rooms",
      flat45: "4.5-rooms",
      flat5: "5 or more",
      other: "Other",
    },
    adPropertyType: {
      flat: "Flat",
      house: "House",
      apartment: "Vacation property",
      room: "Room",
      // duplex: "Twin",
      weekend: "Weekend property",
      estate: "Estate",
      commercial: "Commercial",
      farm: "Farm, forrest, agriculture",
      parking: "Parking, garage",
    },
    adTsPropertyType: {
      "0": "House",
      "1": "Flat",
      "2": "Vacation property",
      "3": "Room",
      "4": "Weekend property",
      "5": "Estate",
      "6": "Commercial",
      "7": "Farm, forrest, agriculture",
      "8": "Parking, garage",
    },
    weekdaysShort: {
      sun: "S",
      mon: "M",
      tue: "T",
      wed: "W",
      thu: "T",
      fri: "F",
      sat: "S",
    },
    amenitiesTranslation: "Amenities",
    amenities: {
      animalsAllowed: "animals allowed",
      alarm: "alarm",
      constructionReady: "construction ready",
      ac: "air conditioning",
      disability: "wheelchair ready",
      dishwasher: "dishwasher",
      heatPump: "heat pump",
      internet: "internet",
      kitchenBasic: "kitchen",
      firstTime: "first vacancy",
      feeFree: "no fees",
      groundFloor: "ground floor",
      parking: "parking",
      garage: "garage",
      balcony: "balcony",
      lift: "lift",
      opticalFiber: "optical internet",
      pool: "pool",
      solar: "solar panel",
      washer: "washing machine",
    },
    findYourNewHome: "Find Your New Home",
    findYourNewHomeDescription: "Buy or rent a house, apartment, flat, office, land or more.",
    alreadyRegistered: "Registration already confirmed.",
    registerConfirmation: "Registration confirmation",
    validationSuccess: "Email validation successful.",
    messageSent: "Message was sent.",
    validationNotComplete: "Email validation was not completed.",
    validationExpired: "Registration period of 24 hours has expired. Please register again.",
    validationEmailSent: "The confirmation link was sent to your Email. Please verify your registration within 24 hours.",
    goToHomepage: "Go to homepage",
    orGoToYour: "Or go to your",
    adCurrentlyNotActive: "This ad is not active at the moment.",
    adInactive: "Inactive",
    activated: "Activated",
    goToAd: "Go to ad",
    detailedSearch: "Detailed search",
    questionsAndComments: "Questions and comments",
    loginToViewComments: "Login to view the comments.",
    myComments: "My comments",
    noCommentsYet: "No comments yet.",
    addComment: "Comment",
    commentInputPlaceholder: "Add public question or comment",
    comments: "Comments",
    addReply: "Reply",
    showReplies: "Show replies",
    hideReplies: "Hide replies",
    openWithGoogleMaps: "Open with Google Maps",
    showOnMap: "Show on map",
    showAll: "Show all",
    show: "Show",
    details: "Details",
    latestAds: "Latest",
    adsBy: "Ads by",
    aboutUs: "About us",
    ad: "Ad",
    newAd: "New ad",
    newAdShort: "New ad",
    filter: "Filter",
    filters: "Filters",
    view: "View",
    result1: "result",
    result2: "results",
    result3: "results",
    result4: "results",
    saveSearch: "Save search",
    save: "save",
    saved: "Saved",
    savedSearches: "Saved searches",
    saveAndPhotos: "Save and add photos",
    savedPhotos: "Saved photos",
    noPhotosSavedYet: "No photo saved yet.",
    addPhoto: "Add photos",
    editPhotos: "Edit photos",
    photo: "Photo",
    photoAccusative: "photo",
    photoAccusativeTwo: "photos",
    photoAccusativeMulti: "photos",
    activationAlertTitle: "Ad activation",
    activationAlertMessage: "Activation will be enabled only after adding at least one image.",
    activationPostTitle: "Post activation",
    activationPostBody: "The post has been submitted and will be activated shortly.",
    buy: "Buy",
    forRent: "Rent",
    type: "Type",
    typeLong: "Type of real estate",
    typeSellerPrivate: "Private",
    typeSellerAgent: "Agent",
    where: "Where",
    offerType: "Offer type",
    energyClass: "Energy class",
    energyCertificate: "Energy certificate",
    certificateInDevelopment: "Certificate pending",
    certificateNotMandatory: "Certificate not mandatory (334. člen EZ-1)",
    certificateNotPossible: "Not available",
    recordedValue: "Recorded value",
    measuredIn: "Measured in",
    price: "Price",
    min: "ascending",
    max: "descending",
    priceHistory: "Price history",
    viewHistory: "View history",
    noHistoryYet: "No history to show.",
    noSearchesYet: "No saved seraches to show.",
    priceFrom: "Price from",
    priceTo: "Price to",
    adSizeFrom: "Size from",
    adSizeTo: "Size to",
    roomsFrom: "Rooms from",
    roomsTo: "Rooms to",
    priceRequired: "Setting a price is required",
    requiredFields: "Required fields must be filled out.",
    costs: "Costs",
    rent: "Rent",
    rentPerMonth: "Rent per month",
    rentPerDay: "Rent per day",
    deposit: "Deposit",
    fees: "Fees",
    moveIn: "For rent from",
    moveOut: "For rent until",
    linkCopied: "Link copied.",
    maintenanceMonthly: "Monthly estimated costs in EUR",
    paymentTerms: "Payment terms",
    payOnce: "One time payment",
    payMonthly: "Monthly price",
    payDaily: "Price per day",
    inSummer: "In summer",
    inWinter: "In winter",
    calendar: "Calendar",
    yearBuilt: "Year built",
    yearAdaptation: "Year of last adaptation",
    neighborhoodDistances: "Neighborhood in km",
    kindergarten: "Kindergarten",
    elementarySchool: "Elementary school",
    highSchool: "High school",
    postOffice: "Post office",
    post: "Post",
    createPost: "Create post",
    medicalStation: "Health station",
    pharmacy: "Pharmacy",
    market: "Shop",
    busStation: "Bus station",
    trainStation: "Train station",
    highway: "Highway",
    park: "Park, forrest",
    fitnessAndSport: "Fitness, Sport",
    floor: "Floor",
    numFloors: "Number of floors",
    heatingType: "Heating type",
    heatingTypes: {
      electricity: "Electricity",
      gas: "Gas",
      oil: "Oil",
      woodCoal: "Wood/coal",
      floorHeating: "Floor heating",
    },
    equippedIn: "Equipped",
    equippedAmount: {
      none: "no",
      partial: "partially",
      full: "fully",
    },
    equipment: "Equipment",
    furnitureAge: "Furniture age",
    adAge: "Age of ad",
    propertyAge: "Age",
    year: "Year",
    change: "Change",
    roomNumber: "Number of rooms",
    bathroomNumber: "Bathroom number",
    parkingNumber: "Parking number",
    garageNumber: "Garage number",
    bedroomNumber: "Bedroom number",
    balconyNumber: "Balcony number",
    balconyArea: "Balcony area",
    livingSpace: "Living area",
    slopeOfEstate: "Slope of estate",
    noSlope: "None",
    moderateSlope: "Moderate",
    steepSlope: "Steep",
    slopeDirection: "Slope direction",
    north: "North",
    east: "East",
    south: "South",
    west: "West",
    spaces: "Area sizes",
    space: "Area size",
    storageArea: "Storage area",
    from: "from",
    to: "to",
    published: "Published",
    author: "Author",
    seller: "Seller",
    contactSeller: "Contact the seller",
    contact: "Contact",
    contact2: "Contact",
    advertising: "Advertising",
    noItemsToShow: "No items to show at the moment.",
    beTheFirstSeller: "Be the first who sells on kvadrat!",
    freeAdvertising: "Advertising on kvadrat is free of charge for agents and private sellers. Contact us for more information.",
    createAdFree: "Start selling now",
    createNewAd: "Create new ad",
    propertyId: "Identification number",
    propertyOnlineInMinutes: "Your property will be online in minutes",
    increaseYourChances: "Increase your chances of finding the right buyer",
    useOurTools: "Use our tools to help you in the sales process",
    views: "Views",
    preview: "Preview",
    saves: "Saves",
    month: "month",
    directions: "Directions",
    rooms: "rooms",
    bedrooms: "beds",
    bathrooms: "baths",
    cards: "cards",
    list: "list",
    map: "map",
    data: "data",
    private: "private seller",
    agent: "agent",
  };

  sl: ITranslations = {
    login: "Vpis",
    loginToContact: "Za kontaktiranje se moraš vpisati.",
    noUserFound: "Uporabnik ne obstaja.",
    email: "Email",
    website: "Spletna stran",
    sendEmail: "Pošlji email",
    send: "Pošlji",
    call: "Pokliči",
    companyName: "Ime podjetja",
    companyId: "Matična številka",
    taxNumber: "Davčna številka",
    registryCity: "Mesto registracije",
    bankName: "Ime banke",
    bank: "Banka",
    basicInformation: "Osnovni podatki",
    iban: "Iban",
    swift: "Swift",
    getPhone: "Prikaži telefon",
    darkModeToggle: "Nočni način",
    lightModeToggle: "Svetli način",
    phoneNumber: "Telefon",
    submitNumber: "Dodaj številko",
    providePhoneNumber: "Dodaj telefonsko številko",
    noPhoneProvided: "Telefonska številka ni dodana.",
    phoneRequirement: "Za dodajanje oglasa je potrebna telefonska številka.",
    personalInfo: "Opis",
    emailNotValid: "Email ni veljaven.",
    userNameNotValid: "Ime mora vsebovati črke brez šumnikov ali številke, brez odvečnih presledkov.",
    userNameTaken: "Uporabnik s tem imenom že obstaja.",
    userNameTooShort: "Uporabniško ime je prekratko.",
    userNameTooLong: "Uporabniško ime je predolgo.",
    userNameRequired: "Uporabniško ime je obvezno.",
    forSupport: "Za pomoč, predloge in prijavo napak nam",
    sendUsMessage: "pošlji sporočilo",
    yourMessage: "Tvoje sporočilo",
    messageTitle: "Zadeva",
    messages: "Sporočila",
    noMessagesYet: "Ni še sporočil.",
    myConversations: "Moji pogovori",
    myConversationWith: "Moj pogovor z",
    password: "Geslo",
    confirmPassword: "Potrdi geslo",
    passwordTooShort: "Geslo mora vsebovati vsaj 6 znakov.",
    passwordsNoMatch: "Gesli se ne ujemata.",
    messageTooLong: "Vaše sporočilo je daljše od 500 znakov.",
    messageToOwner: "Izgleda, kot da ste avtor oglasa.",
    search: "Išči",
    search2: "iskanje",
    mapSearch: "Iskanje po zemljevidu",
    searching: "Iščem",
    locationCityZip: "Kraj, občina, poštna številka",
    location: "Lokacija",
    zip: "pošta",
    city: "kraj",
    county: "občina",
    country: "država",
    noResults: "Ni zadetkov",
    noResultsFound: "Ni zadetkov, poskusi novo",
    showResults: "Prikaži rezultate",
    rememberMe: "Zapomni si me",
    signIn: "Vpis",
    signInGoogle: "Vpis z Google",
    signInFacebook: "Vpis s Facebook",
    forgotPassword: "Pozabljeno geslo?",
    resetPassword: "Ponastavi geslo",
    firstName: "Ime",
    lastName: "Priimek",
    lastViewedAds: "Nazadnje ogledano",
    adExpired: "oglas potekel",
    expiresIn: "Poteče čez",
    expiredSince: "Potekel pred",
    expiresToday: "Poteče danes",
    noExpirationData: "Ni podatka",
    findHomeIn: "Najdi dom v",
    nickName: "vzdevek",
    byRegistering: "Z registracijo se strinjaš z našimi",
    iAgreeTo: "Strinjam se s  ",
    notifyMe: "Obvesti me",
    subscribeToSearchMessage: "Ali se prijavi na iskalni niz in izvej prvi/-a, ko se pojavi ustrezen oglas!",
    subscriptionActive: "Email obveščanje aktivirano za naslednjih 30 dni.",
    subscriptionDeactivated: "Email obveščanje deaktivirano.",
    termsAndConditions: "pogoji in pravili",
    termsAndConditions2: "Pogoji in pravila",
    cookiePolicy: "Politika piškotov",
    privacyPolicy: "Politika zasebnosti",
    register: "Registriraj se",
    registration: "Registracija",
    userVerificationError: "Prišlo je do napake. Poskusi ponovno.",
    userTaken: "Uporabnik s tem emailom že obstaja.",
    wrongPasswordMatch: "Gesli se ne ujemata.",
    noPasswordSet: "Geslo za ta email ni nastavljeno. Poskusi Oauth.",
    options: "Možnosti",
    delete: "Izbriši",
    activate: "Aktiviraj",
    deactivate: "Deaktiviraj",
    edit: "Uredi",
    remove: "Odstrani",
    removePost: "Odstrani objavo",
    removePostConfirm: "Ali želiš odstraniti objavo?",
    whatsOnYourMind: "Kaj imaš v mislih",
    more: "Več",
    id: "ID",
    flag: "Prijavi",
    describeTheIssue: "Opiši težavo.",
    descriptionAd: "Opis",
    deleteConfirm: "Ali želiš izbrisati izbrane elemente?",
    restore: "Obnovi",
    share: "Deli",
    move: "Premakni",
    star: "Med priljubljene",
    color: "Barva",
    cancel: "Prekliči",
    back: "Nazaj",
    close: "Zapri",
    hide: "Skrij",
    home: "Domov",
    recent: "Nedavno",
    favorites: "Priljubljeno",
    noFavoritesYet: "Ni še priljubljenih.",
    bin: "Koš",
    profile: "Profil",
    myDashboard: "Nadzorna plošča",
    profanity: "neprimernost",
    offensive: "sovražnost",
    spam: "spam",
    other: "drugo",
    logout: "Izpis",
    help: "Pomoč",
    or: "ali",
    at: "ob",
    dropFileHere: "Odloži datoteko tukaj",
    uploadLimit: "Maksimalno število fotografij je 12.",
    uploadMorePhotos: "Dodaj več fotografij.",
    uploadConfirm: "Fotografije naložene.",
    uploadFile: "Naloži datoteko",
    upload: "Naloži",
    loadNewPosts: "Naloži nove objave",
    posts: "Objave",
    menu: "meni",
    loading: "Nalaga...",
    fileDeleted: "izbrisano.",
    folderName: "Ime mape",
    size: "Velikost",
    ok: "V redu",
    sortBy: "Razvrsti po",
    setAddressOnMap: "Določi (vsaj približno) lokacijo na zemljevidu.",
    address: "Naslov",
    addressNotFound: "Ulica ni najdena. Prosimo približaj točko bližje cesti.",
    registrationWarning: "OPOZORILO: Bodi previden/-a ko prejmeš sporočila, SMS, klic ali email. Nikoli ne razkrivaj občutljivih podatkov.",
    name: "Ime",
    days: "dni",
    day: "dan",
    dateCreated: "Datum nastanka",
    date: "Datum",
    adType: "Vrsta oglasa",
    adPropType: "Vrsta nepremičnine",
    flatType: "Tip stanovanja",
    flatTypes: {
      garconiere: "Garsonjera",
      flat1: "1-sobno",
      flat15: "1.5-sobno",
      flat2: "2-sobno",
      flat25: "2.5-sobno",
      flat3: "3-sobno",
      flat35: "3.5-sobno",
      flat4: "4-sobno",
      flat45: "4.5-sobno",
      flat5: "5 in več sobno",
      other: "Drugo",
    },
    adPropertyType: {
      flat: "Stanovanje",
      house: "Hiša",
      apartment: "Apartma, turistični",
      room: "Soba",
      // duplex: "Duplex",
      weekend: "Vikend",
      estate: "Parcela, zemljišče",
      commercial: "Poslovno",
      farm: "Kmetija, zemljišče",
      parking: "Parkirni prostor, garaža",
    },
    adTsPropertyType: {
      "0": "Hiša",
      "1": "Stanovanje",
      "2": "Apartma, turistični",
      "3": "Soba",
      "4": "Vikend",
      "5": "Parcela, zemljišče",
      "6": "Poslovno",
      "7": "Kmetija, zemljišče",
      "8": "Parkirni prostor, garaža",
    },
    weekdaysShort: {
      sun: "N",
      mon: "P",
      tue: "T",
      wed: "S",
      thu: "Č",
      fri: "P",
      sat: "S",
    },
    amenitiesTranslation: "Lastnosti",
    amenities: {
      animalsAllowed: "živali dovoljene",
      alarm: "alarm",
      constructionReady: "primerno za gradnjo",
      ac: "klimatizirano",
      disability: "primerno za invalide",
      dishwasher: "pomivalnik posode",
      heatPump: "toplotna črpalka",
      internet: "internet",
      kitchenBasic: "kuhinja",
      firstTime: "prva vselitev",
      feeFree: "brez provizij",
      groundFloor: "pritličje",
      parking: "parkirišče",
      garage: "garaža",
      balcony: "balkon",
      lift: "dvigalo",
      opticalFiber: "optični internet",
      pool: "bazen",
      solar: "solarne celice",
      washer: "pralni stroj",
    },
    findYourNewHome: "Najdi nepremičnino",
    findYourNewHomeDescription: "Najem ter projdaja stanovanj, hiš, gradbenih parcel, poslovnih prostorov in še več.",
    alreadyRegistered: "Registracija je že potrjena.",
    registerConfirmation: "Potrditev registracije",
    validationSuccess: "Email potrditev je bila uspešna.",
    messageSent: "Sporočilo je bilo poslano.",
    validationNotComplete: "Potrditev emaila ni bila dokončana.",
    validationExpired: "Od registracije je minilo več kot 24 ur. Prosimo, registriraj se ponovno!",
    validationEmailSent: "Potrditev je bila poslana na tvoj email. Prosimo, potrdi svojo registracijo v 24-ih urah.",
    goToHomepage: "Pojdi na domačo stran",
    orGoToYour: "Ali pojdi na tvoj",
    adCurrentlyNotActive: "Oglas trenutno ni na voljo.",
    adInactive: "Ni aktivno",
    activated: "Aktivirano",
    goToAd: "Na oglas",
    detailedSearch: "Podrobno iskanje",
    questionsAndComments: "Vprašanja in komentarji",
    loginToViewComments: "Za ogled komentarjev se moraš vpisati.",
    myComments: "Moji komentarji",
    noCommentsYet: "Ni še komentarjev.",
    addComment: "Komentiraj",
    commentInputPlaceholder: "Oddaj javen komentar ali vprašanje",
    comments: "Komentarji",
    addReply: "Odgovori",
    showReplies: "Prikaži odgovore",
    hideReplies: "Skrij odgovore",
    openWithGoogleMaps: "Odpri z Google Maps",
    showOnMap: "Prikaži na zemljevidu",
    showAll: "Prikaži vse",
    show: "Prikaži",
    details: "Podrobnosti",
    latestAds: "Nazadnje objavljeno",
    adsBy: "Oglasi uporabnika",
    aboutUs: "O nas",
    ad: "Oglas",
    newAd: "Nov oglas",
    newAdShort: "Nov",
    filter: "Filtriraj",
    filters: "Filtri",
    view: "Pogled",
    result1: "rezultat",
    result2: "rezultata",
    result3: "rezultati",
    result4: "rezultatov",
    saveSearch: "Shrani iskanje",
    save: "shrani",
    saved: "Shranjeno",
    savedSearches: "Shranjeno iskanje",
    saveAndPhotos: "Shrani in dodaj fotografije",
    savedPhotos: "Shranjene fotografije",
    noPhotosSavedYet: "Shranjena še ni nobena fotografija.",
    addPhoto: "Dodaj fotografije",
    editPhotos: "Uredi fotografije",
    photo: "Slika",
    photoAccusative: "sliko",
    photoAccusativeTwo: "sliki",
    photoAccusativeMulti: "slike",
    activationAlertTitle: "Aktivacija oglasa",
    activationAlertMessage: "Aktivacija oglasa bo možna komaj ko bo naložena vsaj ena slika.",
    activationPostTitle: "Aktivacija objave",
    activationPostBody: "Objava je bila shranjena in bo kmalu aktivirana.",
    buy: "Nakup",
    forRent: "Najem",
    type: "Tip",
    typeLong: "Vrsta nepremičnine",
    typeSellerPrivate: "Privat",
    typeSellerAgent: "Agent",
    where: "Kje",
    offerType: "Vrsta ponudbe",
    energyClass: "Energijski razred",
    energyCertificate: "Energetska izkaznica",
    certificateInDevelopment: "Izkaznica v izdelavi",
    certificateNotMandatory: "Izkaznica ni potrebna po 334. členu EZ-1",
    certificateNotPossible: "Ni mogoče izdati",
    recordedValue: "Izmerjena vrednost",
    measuredIn: "Meritev v",
    price: "Cena",
    min: "naraščajoče",
    max: "padajoče",
    priceHistory: "Zgodovina cene",
    viewHistory: "Zgodovina ogledov",
    noHistoryYet: "Ni zgodovine za ogled.",
    noSearchesYet: "Ni shranjenih iskanj.",
    priceFrom: "Cena od",
    priceTo: "Cena do",
    adSizeFrom: "Velikost od",
    adSizeTo: "Velikost do",
    roomsFrom: "Št. sob od",
    roomsTo: "Št. sob do",
    priceRequired: "Potrebno je določiti ceno",
    requiredFields: "Potrebno je izpolniti vse nujne podatke.",
    costs: "Stroški",
    rent: "Najemnina",
    rentPerMonth: "Najemnina na mesec",
    rentPerDay: "Najemnina na dan",
    deposit: "Kavcija, depozit",
    fees: "Provizije posredovanja",
    moveIn: "Vseljivo od",
    moveOut: "Najem možen do",
    linkCopied: "Link kopiran.",
    maintenanceMonthly: "Mesečni stroški vzdževanja v EUR",
    paymentTerms: "Način plačila",
    payOnce: "Enkratno",
    payMonthly: "Mesečno",
    payDaily: "Dnevno",
    inSummer: "Poleti",
    inWinter: "Pozimi",
    calendar: "Koledar",
    yearBuilt: "Leto gradnje",
    yearAdaptation: "Leto zadnje obnove",
    neighborhoodDistances: "Okolica, oddaljenost v km",
    kindergarten: "Otroški vrtec",
    elementarySchool: "Osnovna šola",
    highSchool: "Višja šola",
    postOffice: "Pošta",
    post: "Objavi",
    createPost: "Dodaj objavo",
    medicalStation: "Zdravstveni dom",
    pharmacy: "Lekarna",
    market: "Trgovina",
    busStation: "Avtobusna postaja",
    trainStation: "Železniška postaja",
    highway: "Avtocesta",
    park: "Park, gozd",
    fitnessAndSport: "Fitnes, športni objekti",
    floor: "Nadstropje",
    numFloors: "Število nadstropij",
    heatingType: "Vrsta ogrevanja",
    heatingTypes: {
      electricity: "Elektrika",
      gas: "Plin",
      oil: "Olje",
      woodCoal: "Les/premog",
      floorHeating: "Talno ogrevanje",
    },
    equippedIn: "Opremljenost",
    equippedAmount: {
      none: "neopremljeno",
      partial: "delno opremljeno",
      full: "polno opremljeno",
    },
    equipment: "Oprema",
    furnitureAge: "Starost pohištva",
    adAge: "Starost oglasa",
    propertyAge: "Startost",
    year: "Leto",
    change: "Spremeni",
    roomNumber: "Število sob",
    bathroomNumber: "Število kopalnic",
    parkingNumber: "Število parkirišč",
    garageNumber: "Število garaž",
    bedroomNumber: "Število spalnic",
    balconyNumber: "Število balkonov",
    balconyArea: "Balkoni površina",
    livingSpace: "Bivalna površina",
    slopeOfEstate: "Naklon parcele",
    noSlope: "Brez",
    moderateSlope: "Zmerno",
    steepSlope: "Strmo",
    slopeDirection: "Smer naklona",
    north: "Sever",
    east: "Vzhod",
    south: "Jug",
    west: "Zahod",
    spaces: "Površine",
    space: "Površina",
    storageArea: "klet / shramba",
    from: "od",
    to: "do",
    published: "Objavljeno",
    author: "Avtor",
    seller: "Prodajalec",
    contactSeller: "Kontaktiraj prodajalca",
    contact: "Kontaktiraj",
    contact2: "Kontakt",
    advertising: "Oglaševanje",
    noItemsToShow: "Trenutno ni predmetov za prikaz.",
    beTheFirstSeller: "Bodi prvi, ki prodaja na kvadratu!",
    freeAdvertising: "Oglaševanje na kvadratu je brezplačno tudi za agente. Kontaktiraj nas za več informacij.",
    createAdFree: "Začni s prodajo zdaj",
    createNewAd: "Oddaj oglas",
    propertyId: "Šifra oglasa",
    propertyOnlineInMinutes: "Tvoja nepremičnina bo na spletu v nekaj minutah",
    increaseYourChances: "Povečaj svoje možnosti pri iskanju pravega kupca",
    useOurTools: "Naj ti naša orodja pomagajo pri prodaji",
    views: "Ogledov",
    preview: "Predogled",
    saves: "Shranjeno",
    month: "mesec",
    directions: "Navodila",
    rooms: "sob",
    bedrooms: "spalnic",
    bathrooms: "kopalnic",
    cards: "kartice",
    list: "seznam",
    map: "zemljevid",
    data: "podatki",
    private: "privatni prodajalec",
    agent: "agent",
  };

  constructor(
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private windowService: WindowService,
    private http: HttpClient
  ) {
    this.API = this.dataService.API;
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.setInitialLanguage();
      }
    });
  }

  setInitialLanguage() {
    const urlParams = new URLSearchParams(
      this.windowService.nativeWindow["location"]["search"]
    );
    const langParam = urlParams.get("l");

    if (langParam && langParam === "en") {
      this.langChange(langParam);
      return;
    }

    // this.browserLanguage = window.navigator['userLanguage'] || window.navigator.language;
    this.browserLanguage =
      this.windowService.nativeWindow["navigator"].language ||
      this.windowService.nativeWindow["navigator"]["userLanguage"];

    // this.browserLanguage = 'en';
    if (this.localStorageService.getItem("selectedLanguage") == null) {
      switch (this.browserLanguage) {
        case "sl":
          this.lang = this.browserLanguage;
          break;
        default:
          this.lang = "en";
          break;
      }
    } else {
      if (this.localStorageService.getItem("selectedLanguage") !== "en") {
        this.lang = "sl";
      } else {
        this.lang = this.localStorageService.getItem("selectedLanguage");
      }
    }
    this.langChange(this.lang);
  }

  langChange(language: string) {
    this.languageSource.next(language);
    // if (window.localStorage) {
    //   localStorage.setItem('selectedLanguage', language);
    // } else {
    //   // FALLBACK
    // }
    this.localStorageService.setItem("selectedLanguage", language);
    this.setLangParam(language);
  }

  setLangParam(language: string) {
    // this.router.navigate([], {
    //   relativeTo: this.windowService.nativeWindow.location.href, // this.route,
    //   queryParams: {
    //     l: language === 'en' ? language : null
    //   },
    //   queryParamsHandling: 'merge',
    //   // preserve the existing query params in the route
    //   skipLocationChange: false
    //   // do not trigger navigation
    // });
    const updatedUrl = new URL(this.windowService.nativeWindow.location.href);
    if (language === "en") {
      updatedUrl.searchParams.append("l", language);
      this.windowService.nativeWindow.history.pushState({}, "", updatedUrl.href);
    } else {
      updatedUrl.searchParams.delete("l");
      this.windowService.nativeWindow.history.pushState({}, "", updatedUrl);
    }
  }

  // Deprecated
  // get language from server
  getLangFromServer(params: Params) {
    return this.http.get(`${this.API}/language/from-param`, {
      params,
    });
  }
}
