import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { IonContent, MenuController, ModalController } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";

import { LanguageService } from "../../services/language.service";
import { LocalStorageService } from "../../services/localstorage.service";
import { LoginService } from "../../services/login.service";
import { DataService } from "../../services/data.service";
import { WindowService } from "../../services/window.service";
import { MetaService } from "../../services/meta.service";

import { CurrentUser } from "../../model/current-user";
import { SearchModalComponent } from "../modals/search-modal/search-modal.component";
import { LocationLink } from "../../model/location-link";
import { LoginModalComponent } from '../modals/login-modal/login-modal.component';
import { IAdClient } from "src/app/model/db/ad";

// import { Location } from "@angular/common";
// import { LocationService } from "../../services/location.service";
@Component({
  selector: "app-home-page",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent /*extends CompleterBaseData*/ implements OnInit {
  // variables
  ads: IAdClient[] = [];
  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn = false;
  layoutGrid: boolean;
  mobileView: boolean;
  title = "Kvadrat";
  latestAdsEnabled = false;
  locations: LocationLink[] = []; // todo: Location type

  @ViewChild('homePage') homePageContent: IonContent;

  constructor(
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    public modalCtrl: ModalController,
    private loginService: LoginService,
    private languageService: LanguageService,
    private menuCtrl: MenuController,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
  ) {}

  ngOnInit() {    
    this.locations = this.route.snapshot.data.locations["locations"];
    this.appLanguage = this.route.snapshot.data["language"]["currentLanguage"];
    this.lang = this.route.snapshot.data["language"]["lang"];

    this.setMetaInfo();

    // GET VIEW
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      if (isBrowser) {
        // subscribe to language change
        this.languageService.language$.subscribe((value) => {
          this.appLanguage = value;
          this.lang = this.languageService[value];
          this.setMetaInfo();
        });
        // this.getCounties();
        this.toggleMobileView();

        // SET LAYOUT
        if (
          this.localStorageService &&
          this.localStorageService.getItem("gridView")
        ) {
          this.layoutGrid = JSON.parse(
            this.localStorageService.getItem("gridView")
          );
        } else {
          // FALLBACK
          this.layoutGrid = false;
        }

        this.dataService.loggedIn$.subscribe((val) => {
          this.loggedIn = val;
          this.currentUser = this.loginService.currentUser();
        });
      }
    });
  }

  ionViewWillEnter () {
    this.homePageContent.scrollToTop();
  }

  createNewAd() {
    if (this.currentUser) {
      this.router.navigate(
        ['/n'],
        { queryParams: { l: this.appLanguage === "en" ? "en" : null } }
      );
    } else {
      this.presentLoginModal();
    }
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage
      },
      cssClass: 'login-modal'
    });
    modal.present();
  }

  setMetaInfo() {
    this.metaService.setMetaDataObject({
      lang: this.lang,
      title: `Kvadrat - ${this.lang["findYourNewHome"]}`,
      description: this.lang["findYourNewHomeDescription"],
      keywords: "buy, sell, rent, condo, flat, apartment, house",
      url: this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si",
      image: (this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si") + "/assets/images/condo-323780.jpg"
    });
  }

  enableLatestAds(event: boolean) {
    this.latestAdsEnabled = event;
  }

  async openSearchModal() {
    const modal = await this.modalCtrl.create({
      component: SearchModalComponent,
      componentProps: {
        lang: this.lang,
        basePath: "h",
        updateAds: (queryParams) => {
          this.goToAds(queryParams);
        },
      },
    });
    await modal.present();
  }

  goToAds(queryParams): void {
    const { nelat, nelng, swlat, swlng, ...newPropertyObject } = queryParams;

    const newUrlString = this.router
      .createUrlTree(
        ["/r"], // Get uri
        { queryParams: { ...newPropertyObject } } // Pass all parameters inside queryParamsObj
      )
      .toString();

    this.router.navigateByUrl(newUrlString);
  }

  saveSort(ev) {
    this.saveLastState();
  }

  saveLastState() {
    // let id;
    if (this.localStorageService) {
      this.localStorageService.setItem(
        "lastView",
        JSON.stringify({
          // folderIds: this.folderIds,
          // title: this.title,
        })
      );
    } else {
      // FALLBACK
    }
  }

  getLastState() {
    if (
      this.localStorageService &&
      this.localStorageService.getItem("lastView")
    ) {
      const lastView = JSON.parse(this.localStorageService.getItem("lastView"));
      if (lastView !== null) {
        // console.log(lastView);
        // this.title = lastView.title;
      }
    } else {
      // FALLBACK
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    // this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      this.menuCtrl.swipeGesture(true);
    } else {
      this.mobileView = false;
      this.menuCtrl.swipeGesture(false);
    }
  }

  updateLayout(layout: any): void {
    this.layoutGrid = layout;
    if (this.localStorageService) {
      this.localStorageService.setItem("gridView", JSON.stringify(layout));
    } else {
      // FALLBACK
    }
  }
}
