<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content
  class="profile-view-history"
  *ngIf="currentUser && currentUser._id"
>
  <ion-grid>
    <ion-card>
      <ion-list>
        <ion-item-divider>
          {{ lang.viewHistory }}
        </ion-item-divider>
        <ion-item *ngIf="currUserViewHistory.length === 0">
          <p>
            {{ lang.noHistoryYet }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
          </p>
        </ion-item>
        <ng-container *ngIf="currUserViewHistory.length > 0">
          <ng-container *ngFor="let historyItem of currUserViewHistory; let i = index">
            <ion-item *ngIf="!mobileView" [ngClass]="{ 'inactive-ad': historyItem.adExpired }">
              <ion-thumbnail>
                <img
                  *ngIf="historyItem.photos && historyItem.photos.length > 0"
                  [src]="API + '/files/image/' + historyItem.photos[0]._id"
                />
              </ion-thumbnail>
              <ion-label>
                <ion-text color="primary">
                  <h2 *ngIf="!historyItem.adExpired">
                    <a [routerLink]="['/ad', historyItem._id]">
                      {{ historyItem.located.street }},
                      {{ historyItem.located.postal }}
                      {{ historyItem.located.district }}
                    </a>
                  </h2>
                  <h2 *ngIf="historyItem.adExpired">
                    <span class="uppercase">{{ lang.adExpired }}</span> -
                    {{ historyItem.located.street }},
                    {{ historyItem.located.postal }}
                    {{ historyItem.located.district }}
                  </h2>
                </ion-text>
                <p>{{ lang.id }}: {{ historyItem._id }}</p>
                <p>
                  <span class="capitalize">{{ lang.saved }}</span
                  >: {{ historyItem.updatedDate | date : "short" }}
                </p>
              </ion-label>
              <ion-button fill="clear" (click)="removeFromViewHistory(historyItem)">
                {{ lang.remove }}
              </ion-button>
            </ion-item>

            <ion-card *ngIf="mobileView" [ngClass]="{ 'inactive-ad': historyItem.adExpired }">
              <img
                *ngIf="historyItem.photos && historyItem.photos.length > 0"
                [src]="API + '/files/image/' + historyItem.photos[0]._id"
              />
              <ion-card-header>
                <ion-card-title>
                  <ion-text color="primary">
                    <h2 *ngIf="!historyItem.adExpired">
                      <a [routerLink]="['/ad', historyItem._id]">
                        {{ historyItem.located.street }},
                        {{ historyItem.located.postal }}
                        {{ historyItem.located.district }}
                      </a>
                    </h2>
                    <h2 *ngIf="historyItem.adExpired">
                      <span class="uppercase">{{ lang.adExpired }}</span> -
                      {{ historyItem.located.street }},
                      {{ historyItem.located.postal }}
                      {{ historyItem.located.district }}
                    </h2>
                  </ion-text>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <p>{{ lang.id }}: {{ historyItem._id }}</p>
                <p>
                  <span class="capitalize">{{ lang.saved }}</span
                  >: {{ historyItem.updatedDate | date : "short" }}
                </p>
                <ion-button fill="clear" (click)="removeFromViewHistory(historyItem)">
                  {{ lang.remove }}
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ng-container>
        </ng-container>
      </ion-list>
    </ion-card>
  </ion-grid>
</ion-content>
