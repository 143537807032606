import { Component, HostListener, OnInit } from '@angular/core';

import { BucketService } from '../../services/bucket.service';
import { DataService } from '../../services/data.service';
import { LoginService } from '../../services/login.service';
import { LanguageService } from '../../services/language.service';
import { FavoritesService } from '../../services/favorites.service';
import { WindowService } from '../../services/window.service';
import { CurrentUser } from '../../model/current-user';
import { AdStatus } from 'src/app/model/enums/ad-status';
@Component({
  selector: 'app-profile-favorites',
  templateUrl: 'profile-favorites.component.html',
  styleUrls: ['./profile-favorites.component.scss']
})
export class ProfileFavoritesComponent implements OnInit {

  title = 'Favorites';
  lang: any;
  isLoggedIn = false;
  currentUser: CurrentUser;
  currUserFavorites: any = [];

  mobileView = true; // todo
  API = '';

  constructor(
    private bucketService: BucketService,
    private loginService: LoginService,
    private dataService: DataService,
    private languageService: LanguageService,
    private favoritesService: FavoritesService,
    private windowService: WindowService,
  ) { }

  ngOnInit() {
    this.API = this.dataService.API;
    // this.getUrl();
    // this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe(value => {
      // this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
    });

    this.dataService.loggedIn$
      .subscribe(result => {
        this.isLoggedIn = result;
        this.currentUser = this.loginService.currentUser();
        if (this.currentUser && this.currentUser._id) {
          this.getUserFavorites(this.currentUser._id);
        }
      });
    
    this.dataService.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });
  }

  ionViewDidEnter() {
    if (this.currentUser && this.currentUser._id) {
      this.getUserFavorites(this.currentUser._id);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= '767') {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  getUserFavorites(userId) {
    this.favoritesService.listUserFavorites(userId)
      .subscribe(result => {
        this.currUserFavorites = [];
        result['favorites'].forEach(element => {
          this.pushAdToList(element.itemId, this.currUserFavorites, element.created);
        });
      });
  }

  pushAdToList(adId, itemList, updatedDate) {
    this.bucketService.getSinglePreview(adId, '')
      .subscribe(res => {
        const ad = res;
        this.listAdImages(ad);
        this.checkAdExpiry(ad);
        this.setUpdatedDate(ad, updatedDate);
        itemList.push(ad);
      });
  }

  listAdImages(ad): void {
    this.bucketService.listAdImages(ad._id, 3)
      .subscribe(images => {
        ad['photos'] = images['files'];
      });
  }

  setUpdatedDate(ad, updatedDate) {
    ad['updatedDate'] = updatedDate;
  }

  removeFromFavorites(favorite) {
    this.favoritesService.removeFavorite(favorite._id, this.currentUser._id, 'someOwnerId')
      .subscribe(response => {
        this.currUserFavorites = this.currUserFavorites.filter(item => item._id !== favorite._id);
      });
  }

  checkAdExpiry(ad) {
    if (ad.statusOfAd[ad.statusOfAd.length - 1].status === AdStatus.Active &&
      (new Date(ad.statusOfAd[ad.statusOfAd.length - 1].changed).getTime()) > (new Date().getTime() - (30 * 24 * 60 * 60 * 1000))) {
      ad['adExpired'] = false;
    } else {
      ad['adExpired'] = true;
    }
  }
}