<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content
  class="profile-favorites-component"
  *ngIf="currentUser && currentUser._id">
  <ion-grid>
    <ion-card>
      <ion-list>
        <ion-item-divider>
          {{ lang.favorites }}
        </ion-item-divider>
        <ion-item *ngIf="currUserFavorites.length === 0">
          <p>
            {{ lang.noFavoritesYet }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
          </p>
        </ion-item>
        <ng-container *ngIf="currUserFavorites.length > 0">
          <ng-container *ngFor="let favorite of currUserFavorites; let i = index">
            <ion-item *ngIf="!mobileView" [ngClass]="{ 'inactive-ad': favorite.adExpired }">
              <ion-thumbnail>
                <img
                  *ngIf="favorite.photos && favorite.photos.length > 0"
                  [src]="API + '/files/image/' + favorite.photos[0]._id"
                />
              </ion-thumbnail>
              <ion-label>
                <ion-text color="primary">
                  <h2 *ngIf="!favorite.adExpired">
                    <a [routerLink]="['/ad', favorite._id]">
                      {{ favorite.located.street }}, {{ favorite.located.postal }}
                      {{ favorite.located.district }}
                    </a>
                  </h2>
                  <h2 *ngIf="favorite.adExpired">
                    <span class="uppercase">{{ lang.adExpired }}</span> -
                    {{ favorite.located.street }}, {{ favorite.located.postal }}
                    {{ favorite.located.district }}
                  </h2>
                </ion-text>
                <p>{{ lang.id }}: {{ favorite._id }}</p>
                <p>
                  <span class="capitalize">{{ lang.saved }}</span
                  >: {{ favorite.updatedDate | date : "short" }}
                </p>
              </ion-label>
              <ion-button fill="clear" (click)="removeFromFavorites(favorite)">
                {{ lang.remove }}</ion-button>
            </ion-item>

            <ion-card *ngIf="mobileView" [ngClass]="{ 'inactive-ad': favorite.adExpired }">
              <img
                  *ngIf="favorite.photos && favorite.photos.length > 0"
                  [src]="API + '/files/image/' + favorite.photos[0]._id"
                />
                <ion-card-header>
                  <ion-card-title>
                    <ion-text color="primary">
                      <h2 *ngIf="!favorite.adExpired">
                        <a [routerLink]="['/ad', favorite._id]">
                          {{ favorite.located.street }}, {{ favorite.located.postal }}
                          {{ favorite.located.district }}
                        </a>
                      </h2>
                      <h2 *ngIf="favorite.adExpired">
                        <span class="uppercase">{{ lang.adExpired }}</span> -
                        {{ favorite.located.street }}, {{ favorite.located.postal }}
                        {{ favorite.located.district }}
                      </h2>
                    </ion-text>
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>{{ lang.id }}: {{ favorite._id }}</p>
                  <p>
                    <span class="capitalize">{{ lang.saved }}</span
                    >: {{ favorite.updatedDate | date : "short" }}
                  </p>
                  <ion-button fill="clear" (click)="removeFromFavorites(favorite)">
                    {{ lang.remove }}</ion-button>
                </ion-card-content>
            </ion-card>
          </ng-container>
        </ng-container>
      </ion-list>
    </ion-card>
  </ion-grid>
</ion-content>
